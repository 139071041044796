@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@layer base {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    html {
        font-family: 'Fira Sans', sans-serif;
        text-rendering: optimizespeed;
        overflow: hidden overlay;
        overscroll-behavior: contain;
        will-change: scroll-position;
    }

    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 50px;
        @apply bg-white;
    }

    /* Tool tip */
    .tooltip {
        opacity: 0;
        position: absolute;
        bottom: 80%;
        left: 50%;
        transform: translateX(-50%);
        padding: 6px;
        border-radius: 50px;
        color: #fff;
        background: #555;
        font-size: 12px;
        transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .tooltip::after {
        position: absolute;
        top: 90%;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        border-width: 8px 10px;
        border-style: solid;
        border-color: #555 transparent transparent;
        /* transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55); */
    }

    .social:hover > .tooltip {
        opacity: 1;
        bottom: 120%;
    }

    .bg-filter {
        backdrop-filter: blur(40px);
    }

    /* Tab Link */
    .tab-active {
        @apply aspect-square rounded-full flex items-center justify-center flex-col cursor-pointer bg-tab-active shadow-btn-tab text-white lg:rounded-[1rem] lg:text-tab-text;
    }

    .tab {
        @apply aspect-square rounded-full flex items-center justify-center flex-col cursor-pointer bg-white hover:bg-tab-hover hover:shadow-btn-tab text-tab-text lg:rounded-[1rem];
    }

    .text-title {
        @apply relative w-fit text-[22px] text-primary font-bold mb-4 after:absolute after:top-full after:left-0 after:w-1/2 after:h-[5px] after:rounded-sm after:bg-tab-active md:text-[28px] lg:text-[35px];
    }

    .text-sub-title {
        @apply relative w-fit text-[20px] text-primary font-bold mb-4 after:absolute after:top-full after:left-0 after:w-1/2 after:h-[3px] after:rounded-sm after:bg-tab-active md:text-[24px] lg:text-[30px];
    }

    /* Text */

    .typed-animation {
        display: inline-block;
    }

    .typed-out {
        overflow: hidden;
        white-space: nowrap;
        border-right: 0.25rem solid transparent;
        font-size: 3rem;
        font-weight: 700;
        animation: typing 8s steps(40) infinite, blinking 1s infinite;
    }

    /* Loading */

    .loader-experience {
        width: 16px;
        height: 16px;
        border-radius: 100rem;
        margin: 10px auto;
        position: relative;
        @apply bg-blue-default;
    }

    .loader-experience:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background-color: inherit;
        animation: fade 1s forwards infinite linear;
    }

    @keyframes fade {
        to {
            transform: scale(2);
            opacity: 0;
        }
    }

    @keyframes typing {
        0%,
        100% {
            width: 0;
        }

        50%,
        90% {
            width: 100%;
        }
    }

    @keyframes blinking {
        0%,
        100% {
            border-color: transparent;
        }

        50% {
            border-color: #fff;
        }
    }

    /* Download cv */
    @keyframes bounce-cv {
        50% {
            transform: translateY(-2px);
        }
        ,
        100% {
            transform: translateY(0);
        }
    }

    .text-animation > p {
        animation: bounce-cv 0.6s linear;
    }

    .text-animation > p:nth-child(2) {
        animation-delay: 0.1s;
    }

    .text-animation > p:nth-child(3) {
        animation-delay: 0.2s;
    }
    .text-animation > p:nth-child(4) {
        animation-delay: 0.3s;
    }
    .text-animation > p:nth-child(5) {
        animation-delay: 0.4s;
    }
    .text-animation > p:nth-child(6) {
        animation-delay: 0.5s;
    }
    .text-animation > p:nth-child(7) {
        animation-delay: 0.6s;
    }
    .text-animation > p:nth-child(8) {
        animation-delay: 0.7s;
    }
    .text-animation > p:nth-child(9) {
        animation-delay: 0.8s;
    }
    .text-animation > p:last-child {
        animation-delay: 0.9s;
    }
}
