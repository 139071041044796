/* Custom react slick slider */
.slick-arrow {
    z-index: 10;
    color: #000;
    height: 40px;
    width: 40px;
    background: #fff;
    border-radius: 50px;
    padding: 10px;
    @apply shadow-md;
}
.slick-arrow:hover {
    background: #fff;
    color: #000;
}

.slick-next {
    right: -20px;
}

.item-hover:hover > div {
    opacity: 1;
}

.slick-prev {
    left: -20px;
}
