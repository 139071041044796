@keyframes dot-flasing {
    0% {
        background-color: #1877f2;
    }

    50%,
    100% {
        background-color: #ebe6ff;
    }
}

.dot-flasing {
    display: flex;
    justify-content: center;
}

.dot-flasing > div {
    margin: 0 0.3rem;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: #1877f2;
    animation: dot-flasing 0.8s infinite alternate;
}

.dot-flasing > div:first-child {
    animation-delay: 0s;
}

.dot-flasing > div:nth-child(2) {
    animation-delay: 0.2s;
}

.dot-flasing > div:nth-child(3) {
    animation-delay: 0.4s;
}

.dot-flasing > div:last-child {
    animation-delay: 0.6s;
}
